import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Stack, TextField, Typography } from '@mui/material'
import { CSSProperties } from 'react'

type GigiAlertProps = {
  text: string
  medNames: string[]
}

type ReshapeReportProps = {
  text: string
  highlightWords: string[]
}

type AccuracyAlertProps = {
  text: string
  highlightWord: string
}

type MedChangeProps = {
  text: string
  medName: string
}

export const MedChangeTypography: FC<MedChangeProps> = ({ text, medName }) => (
  <>
    <Typography textAlign="left" sx={{ fontSize: 16 }}>
      {'☑︎ '}
      <span style={{ color: '#3885c7', fontWeight: 'bold' }}>{medName}</span>
      <br />
    </Typography>
    <Typography textAlign="left" sx={{ fontSize: 16, pl: 2 }}>
      {`${text}`}
    </Typography>
  </>
)

export const GigiAlertTypography: FC<GigiAlertProps> = ({ text, medNames }) => (
  <>
    <Typography textAlign="left" sx={{ fontSize: 16 }}>
      {'☑︎ '}
      {medNames.map((med, index) => (
        <span key={index}>
          <span style={{ color: '#3885c7', fontWeight: 'bold' }}>{med}</span>
          {index !== medNames.length - 1 && <br />}
          {'　'}
        </span>
      ))}
      <br />
    </Typography>
    <Typography textAlign="left" sx={{ fontSize: 16, pl: 2 }}>
      {`${text}`}
    </Typography>
  </>
)

export const ReshapeReportTypography: FC<ReshapeReportProps> = ({
  text,
  highlightWords,
}) => (
  <>
    <Typography textAlign="left" sx={{ fontSize: 16 }}>
      {'☑︎ '}
      {highlightWords.map((med, index) => (
        <span key={index}>
          <span style={{ color: '#3885c7', fontWeight: 'bold' }}>{med}</span>
          {index !== highlightWords.length - 1 && <br />}
          {'　'}
        </span>
      ))}
      <br />
    </Typography>
    <Typography textAlign="left" sx={{ fontSize: 16, pl: 2 }}>
      {`${text}`}
    </Typography>
  </>
)

export const AccuracyAlertTypography: FC<AccuracyAlertProps> = ({
  text,
  highlightWord,
}) => (
  <>
    <Typography
      textAlign="left"
      sx={{
        fontSize: 16,
      }}
    >
      {'☑︎ '}
      <span style={{ color: '#3885c7', fontWeight: 'bold' }}>
        {highlightWord}
      </span>
      <br />
    </Typography>
    <Typography textAlign="left" sx={{ fontSize: 16, pl: 2 }}>
      {`${text}`}
    </Typography>
  </>
)
