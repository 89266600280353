import { localStorageWrapper } from '../helpers/localStorage'
import { DisplayItemKey } from '../types/models/Presc'

export const API_HOST = process.env.REACT_APP_API_HOST
export const REACT_APP_SENTRY_URL = process.env.REACT_APP_SENTRY_URL
export const AMPLITUDE_ID = process.env.REACT_APP_AMPLITUDE_ID

export const PRESCRIPTION_INFO = {
  SEX_LIST: [
    {
      label: '男性',
      value: '1',
    },
    {
      label: '女性',
      value: '2',
    },
  ],
  INSURANCE_LIST: [
    {
      label: '被保険者',
      value: '1',
    },
    {
      label: '被扶養者',
      value: '2',
    },
  ],
}

export const REPORT_LABEL_ROWS_LIST = [
  ['全体的に精度を上げたい'],
  ['薬剤名', '用量・用法・コメント'],
  ['患者氏名・性別・生年月日', '医療機関名・医師氏名'],
  ['保険者番号・公費番号・記号番号', 'その他(交付年月日・保険区分他)'],
]

export const editableItemFontSize =
  localStorageWrapper.getItem('editableItemFontSize') || '1rem'

export const IMAGE_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://d1pxjqzhcmto7u.cloudfront.net/media/'
    : 'http://127.0.0.1:9001/prescription/media/'

export const QR_CODE_WIDTH = 160

export const INPUTBOX_MAX_WIDTH = {
  patientInfo: 252,
}

export const CHECKBOX_MAX_WIDTH = {
  patientInfo: 240,
}

export const SELECTBOX_MAX_WIDTH = {
  medicine: 400,
  usage: 400,
}

export const CHECKBOX_ITEM_KEYS = [
  'id_12_patient_sex',
  'id_23_insurance_type',
] as DisplayItemKey[]
export const NON_EDITABLE_ITEM_KEYS = [
  'id_11_patient_name_kanji',
  'id_1_hospital_name',
  'id_4_hospital_department_name',
  'id_5_doctor_name_kanji',
  'id_23_insurance_card_kigou',
  'id_23_insurance_card_bangou',
  'id_27_1st_kouhi_futansha_num',
  'id_27_1st_kouhi_jukyuusha_num',
] as DisplayItemKey[]
export const EDITABLE_ITEM_KEYS = [
  'id_13_patient_birthday',
  'id_22_insurance_patient_num',
] as DisplayItemKey[]

export const EDITABLE_ITEM_WITH_SELECT_BOX_KEYS = [
  'id_11_patient_name_kana',
] as DisplayItemKey[]

export const DISPLAY_ITEMS = [
  'id_11_patient_name_kana',
  'id_22_insurance_patient_num',
  'id_13_patient_birthday',
  'id_1_hospital_name',
  'id_5_doctor_name_kanji',
  'id_23_insurance_card_kigou',
  'id_23_insurance_card_bangou',
  'id_27_1st_kouhi_futansha_num',
  'id_27_1st_kouhi_jukyuusha_num',
  'id_12_patient_sex',
  'id_23_insurance_type',
  'id_4_hospital_department_name',
  'id_11_patient_name_kanji',
] as DisplayItemKey[]

export const BASIC_RESULT_LABEL = {
  id_1_hospital_code: '医療機関コード',
  id_12_patient_sex: '性別',
  id_23_insurance_type: '保険種別',
  id_1_hospital_name: '医療機関名',
  id_5_doctor_name_kana: '医師名カナ',
  id_13_patient_birthday: '生年月日',
  id_5_doctor_name_kanji: '医師名漢字',
  id_1_hospital_code_type: '医療機関コード種別',
  id_51_prescription_date: '処方年月日',
  id_1_hospital_place_code: '医療機関都道府県コード',
  id_11_patient_name_kana: '患者名カナ',
  id_23_insurance_card_kigou: '記号',
  id_11_patient_name_kanji: '患者名漢字',
  id_23_insurance_card_bangou: '番号',
  id_23_insurance_card_edaban: '枝番',
  id_27_1st_kouhi_futansha_num: '公費負担者番号',
  id_27_1st_kouhi_jukyuusha_num: '公費受給者番号',
  id_22_insurance_patient_num: '保険者番号',
  id_51_prescription_date_nonJAHIS: '処方箋発行日',
  id_4_hospital_department_name: '診療科名',
}

export const SERVICE_NAMES = {
  TMN: 'RXクラウド',
  DEFAULT: '調剤入力支援AI「まもる君」',
}

export const SHORT_SERVICE_NAMES = {
  TMN: 'RXクラウド',
  DEFAULT: 'まもる君',
}

export const YEARS: string[] = Array.from(
  { length: new Date().getFullYear() - 1899 },
  (_, i) => `${1900 + i}年`
)

export const MONTHS = [
  '01月',
  '02月',
  '03月',
  '04月',
  '05月',
  '06月',
  '07月',
  '08月',
  '09月',
  '10月',
  '11月',
  '12月',
]

type MonthDays = {
  [key: string]: string[]
}

export const DAYS = {
  '01月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
    '31日',
  ],
  '02月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
  ],
  '03月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
    '31日',
  ],
  '04月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
  ],
  '05月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
    '31日',
  ],
  '06月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
  ],
  '07月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
    '31日',
  ],
  '08月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
    '31日',
  ],
  '09月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
  ],
  '10月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
    '31日',
  ],
  '11月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
  ],
  '12月': [
    '01日',
    '02日',
    '03日',
    '04日',
    '05日',
    '06日',
    '07日',
    '08日',
    '09日',
    '10日',
    '11日',
    '12日',
    '13日',
    '14日',
    '15日',
    '16日',
    '17日',
    '18日',
    '19日',
    '20日',
    '21日',
    '22日',
    '23日',
    '24日',
    '25日',
    '26日',
    '27日',
    '28日',
    '29日',
    '30日',
    '31日',
  ],
} as MonthDays

type Era = {
  name: Gengou
  startYear: number
  endYear: number
  startEra: number
}

const eras: Era[] = [
  {
    name: '令和',
    startYear: 2019,
    endYear: new Date().getFullYear(),
    startEra: 1,
  },
  { name: '平成', startYear: 1989, endYear: 2019, startEra: 1 },
  { name: '昭和', startYear: 1926, endYear: 1989, startEra: 1 },
  { name: '大正', startYear: 1912, endYear: 1926, startEra: 1 },
  {
    name: '明治',
    startYear: 1868,
    endYear: 1912,
    startEra: 1,
  },
]

type Gengou = '令和' | '平成' | '昭和' | '大正' | '明治'

export const toGregorian = (year: number, era: Gengou): number => {
  const eraInfo = eras.find((e) => e.name === era)
  if (!eraInfo) {
    throw new Error(`Unknown era: ${era}`)
  }
  return eraInfo.startYear + (year - eraInfo.startEra)
}

export const toJapaneseEra = (year: number): string => {
  for (const era of eras) {
    if (year >= era.startYear) {
      return era.name + (year - era.startYear + era.startEra) + '年'
    }
  }
  throw new Error(`Year out of range: ${year}`)
}

export const isJapaneseDate = (dateStr: string): boolean => {
  const japaneseEraMatch = dateStr.match(
    /(令和|平成|昭和|大正|明治)(元|\d+)年(\d+)月(\d+)日/
  )
  return japaneseEraMatch !== null
}

export const parseDate = (dateStr: string) => {
  const japaneseEraMatch = dateStr.match(
    /(令和|平成|昭和|大正|明治)(元|\d+)年(\d+)月(\d+)日/
  )
  if (japaneseEraMatch) {
    const [, era, y, m, d] = japaneseEraMatch
    const year = y === '元' ? 1 : Number(y)
    const gregorianYear = toGregorian(year, era as Gengou)
    return {
      isSeireki: false,
      gregorianYear: `${gregorianYear}年`,
      gengou: era,
      wareki: `${y}年`,
      month: `${m}月`,
      day: `${d}日`,
    }
  }

  const gregorianMatch = dateStr.match(/(\d{4})年(\d{1,2})月(\d{1,2})日/)
  if (gregorianMatch) {
    const [, y, m, d] = gregorianMatch
    const japaneseEra = toJapaneseEra(Number(y))
    const era = japaneseEra.slice(0, 2) as Gengou
    const targetEra = eras.find((e) => e.name === era) || eras[0]
    const eraYear = Number(y) - targetEra.startYear + 1
    const japaneseYear = eraYear === 1 ? '01' : eraYear.toString()
    return {
      isSeireki: true,
      gregorianYear: `${y}年`,
      gengou: japaneseEra.slice(0, 2),
      wareki: `${japaneseYear}年`,
      month: `${m}月`,
      day: `${d}日`,
    }
  }
  return {
    isSeireki: true,
    gregorianYear: `$xxx年`,
    gengou: `$xxx`,
    wareki: `xxxx年`,
    month: `xx月`,
    day: `$xx日`,
  }
}

export const generateYearList = (eraName: string): string[] => {
  const era = eras.find((e) => e.name === eraName)
  if (!era) {
    return []
  }

  const years: string[] = []
  for (let year = era.startYear; year <= era.endYear; year++) {
    const eraYear = year - era.startYear + era.startEra
    const eraYearStr = eraYear.toString().padStart(2, '0')
    years.push(`${eraYearStr}年`)
  }

  return years
}
