import React, { FC, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { ButtonBase, IconButton, Snackbar, Stack } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

type Props = {
  value: string
}

export const Clipboard: FC<Props> = ({ value }) => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)

  const copyToClipboard = async () => {
    const copyStr = value
    await navigator.clipboard.writeText(copyStr)
    setIsSnackBarOpen(true)
    setTimeout(() => {
      setIsSnackBarOpen(false)
    }, 2000)
  }

  return (
    <Stack>
      <>
        <IconButton size="small" onClick={() => copyToClipboard()}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
        <Snackbar
          sx={{ width: 40 }}
          open={isSnackBarOpen}
          message="コピーしました"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        />
      </>
    </Stack>
  )
}
