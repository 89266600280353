import React, { FC, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import {
  ButtonBase,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { CSSProperties } from 'react'
import { keyframes } from '@emotion/react'
import EditIcon from '@mui/icons-material/Edit'
import { BASIC_RESULT_LABEL, editableItemFontSize } from '../../constants/const'
import { Clipboard } from '../templates/Clipboard'
import { localStorageWrapper } from '../../helpers/localStorage'

type Props = {
  label: string
  value: string
  maxWidth?: CSSProperties['maxWidth']
  hasHighCheckPriority?: boolean
  onClick: () => void
  isHighlighted: boolean
}
const StyledInputBox = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '32px',
    borderRadius: '0',
    '& input': {
      fontSize: editableItemFontSize,
    },
  },
  width: '100%',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: 'translate(20%, 20%)',
  },
})

export const EditableInputBox: FC<Props> = ({
  label,
  value,
  hasHighCheckPriority,
  maxWidth,
  onClick,
  isHighlighted,
}) => {
  const theme = useTheme()
  const inputBoxColor = isHighlighted
    ? theme.palette.primary.dark
    : theme.palette.secondary.main

  const isNameAbleToCopy = localStorageWrapper.getItem('is_name_able_to_copy')

  return (
    <Stack direction="row" spacing={1}>
      <ButtonBase style={{ width: '100%', maxWidth: maxWidth }}>
        <StyledInputBox
          label={label}
          value={value}
          onClick={onClick}
          style={{
            maxWidth: maxWidth,
            backgroundColor: hasHighCheckPriority
              ? theme.palette.warning.light
              : '#fff',
          }}
          InputProps={{
            endAdornment: isHighlighted && <EditIcon />,
            readOnly: true,
            ...(isHighlighted
              ? {}
              : {
                  // 条件に基づいてアイコンを表示
                  endAdornment: <EditIcon />,
                }),
          }}
          InputLabelProps={{
            sx: {
              color: inputBoxColor,
              '&.Mui-focused': {
                color: inputBoxColor,
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: inputBoxColor,
                borderWidth: isHighlighted ? '2px' : '1px',
              },
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: inputBoxColor,
              },
            },
          }}
        />
      </ButtonBase>
      {isNameAbleToCopy &&
        BASIC_RESULT_LABEL['id_11_patient_name_kana'] === label && (
          <Clipboard value={value} />
        )}
    </Stack>
  )
}
